import * as React from "react"
import { StoreProvider } from "./src/context/store-context"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export const wrapRootElement = ({ element }) => <StoreProvider>{element}</StoreProvider>

export const onRouteUpdate = () => {
    const width = window.innerWidth
    const targetVideo = document.getElementById("first-view-video")

    if (width < 600 || targetVideo == null) return

    for (let source in targetVideo.children) {
        let videoSource = targetVideo.children[source]
        if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
            videoSource.src = videoSource.dataset.src
        }
    }

    targetVideo.load()
}
